<template>
  <div class="home">
    <a-row type="flex" justify="start" :gutter="[16, 16]">
      <a-col :xs="24" :md="12">
        Hello World
      </a-col>
    </a-row>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {};
  },
  components: {
  },
  methods: {},
  mounted() {
  },
};
</script>
